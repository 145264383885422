import "./index.css";
import { Container, Row, Col } from "react-grid-system";
import React, { useEffect, useState } from "react";
import "react-slideshow-image/dist/styles.css";
import "./index.css";
import api from "../../services/api";
import { CardPublication } from "../CardPublication";

export function LastByCategory(props) {
  const [publications, setPublications] = useState([]);
  useEffect(() => {
    async function fetchLastByCategory() {
      try {
        const publicationList = await api.get(`/publications/last-by-category`);

        return setPublications(publicationList.data);
      } catch (err) {
        console.log(err.response.data.message || err.response.message);
      }
    }
    fetchLastByCategory();
  }, []);
  return (
    <div className="cards-container">
      <div className="header-cards-container">
        <h3>Últimas notícias</h3>
      </div>
      <Container>
        <Row gutterWidth={70}>
          {publications.map((publication, index) => (
            <Col
              xs={12}
              lg={publications.length === 1 ? 12 : 6}
              key={index}
            >
              <CardPublication publication={publication}></CardPublication>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
