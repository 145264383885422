/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import api from "../../../services/api.js";
import "./styles.css";
import { Header } from "../../../components/Header/index.jsx";
import { Main } from "../../../components/Main/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { Helmet } from "react-helmet";
export function ShowPublication() {
  const location = useLocation();
  const [publication, setPublication] = useState({
    title: "",
    content: "",
    images: [],
    gdrive_image_ids: [], //descontinuado
    youtube_video_id: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const { external_publication_id } = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(
          `/publications/${external_publication_id}`
        );
        const publication = response.data;
        return setPublication(publication);
      } catch (err) {
        console.log(err.response.data.message);
        setErrorMessage(err.response.data.message);
      }
    }
    fetchData();
  }, [external_publication_id]);
  const shareUrl = "https://www.caderno3.com.br" + location.pathname;
  return (
    <>
      <Header></Header>
      <Main>
        <Helmet>
          <meta
            name="description"
            content={publication.title}
            data-react-helmet="true"
          />
          <meta
            name="quote"
            content={publication.title}
            data-react-helmet="true"
          />
          <meta name="url" content={shareUrl} data-react-helmet="true" />
          <meta
            property="image"
            content={publication.images[0]}
            data-react-helmet="true"
          />
          <meta
            name="og:description"
            content={publication.title}
            data-react-helmet="true"
          />
          <meta
            name="og:quote"
            content={publication.title}
            data-react-helmet="true"
          />
          <meta name="og:url" content={shareUrl} data-react-helmet="true" />
          <meta
            name="og:image"
            content={publication.images[0]}
            data-react-helmet="true"
          />
          <meta
            content="image/*"
            property="og:image:type"
            data-react-helmet="true"
          />
        </Helmet>
        <div className="social-buttons">
          <FacebookShareButton
            className="social-buttons-item"
            url={shareUrl}
            quote={publication.title}
            windowHeight={700}
            windowWidth={800}
          >
            <FacebookIcon size={40} />
          </FacebookShareButton>

          <WhatsappShareButton
            className="social-buttons-item"
            url={shareUrl}
            title={publication.title}
            windowHeight={700}
            windowWidth={800}
          >
            <WhatsappIcon size={40} />
          </WhatsappShareButton>
        </div>
        <article className="show-publication">
          {errorMessage && (
            <label className="show-publication-error-message">
              <p htmlFor="show-publication-error-message">{errorMessage}</p>
            </label>
          )}
          <h2>{publication.title}</h2>
          <div>
            <ReactQuill readOnly theme="bubble" value={publication.content} />
          </div>

          {publication.gdrive_image_ids.map(
            (image_id) =>
              image_id && (
                <div key={image_id} className="show-publication-images">
                  <img
                    // src={`https://drive.google.com/uc?export=view&id=${image_id}`}
                    src={`https://drive.google.com/thumbnail?id=${image_id}&sz=w1000`}
                    alt=""
                  ></img>
                </div>
              )
          )}
          {publication.images.map(
            (image, i) =>
              image && (
                <div key={i} className="show-publication-images">
                  <img src={image} alt=""></img>
                </div>
              )
          )}
          {publication.youtube_video_id && (
            <iframe
              className="show-publication-images"
              width="100%"
              height="400"
              src={`https://www.youtube.com/embed/${publication.youtube_video_id}`}
              title="YouTube video player"
              style={{ border: 0 }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </article>
      </Main>
      <Footer></Footer>
    </>
  );
}
