/* eslint-disable import/no-anonymous-default-export */
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/index.jsx";
import api from "../../../services/api.js";
import "./styles.css";
import { convertToBase64 } from "../../../utils/convertToBase64.js";
import { Header } from "../../../components/Header/index.jsx";
import { MainRaw } from "../../../components/MainRaw/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";
import InputMask from "react-input-mask";
export function NewAdvertiser() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ type: "Pessoa Física" });
  const [errorMessage, setErrorMessage] = useState("");
  const desktopImageInput = useRef(null);
  const mobileImageInput = useRef(null);

  const handleInputChange = async (event) => {
    setErrorMessage("");
    const { name, value, type, checked, files } = event.target;
    if (type === "checkbox") {
      return setFormValues({
        ...formValues,
        [name]: checked,
      });
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { desktopImage, mobileImage, ...data } = formValues;
    if (data.type === "Pessoa Física") {
      data.company_name = null;
      data.business_name = null;
      data.cnpj = null;
      data.state_registration = null;
      data.municipal_registration = null;
      data.cpf = String(data.cpf).match(/\d/g)?.join("") || null;
    }
    if (data.type === "Pessoa Jurídica") {
      data.name = null;
      data.cpf = null;
      data.cnpj = String(data.cnpj).match(/\d/g)?.join("") || null;
    }
    try {
      await api.post(`/advertisers`, data);
      navigate("/advertisers");
    } catch (err) {
      console.log(err.response.data.message || err.response.message);
      setErrorMessage(err.response.data.message || err.response.message);
    }
  };
  return (
    <>
      <Header></Header>
      <MainRaw>
        <form
          className="new-advertiser"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <h4>Cadastrar Anunciante</h4>

          <div className="new-advertiser-type">
            <label>
              <input
                type="radio"
                name="type"
                value="Pessoa Física"
                onChange={handleInputChange}
                checked={formValues.type === "Pessoa Física"}
              />
              Pessoa Física
            </label>
            <label>
              <input
                type="radio"
                name="type"
                value="Pessoa Jurídica"
                onChange={handleInputChange}
                checked={formValues.type === "Pessoa Jurídica"}
              />
              Pessoa Jurídica
            </label>
          </div>
          {formValues.type === "Pessoa Física" ? (
            <>
              <label>Nome</label>
              <input
                type="text"
                name="name"
                onChange={handleInputChange}
                value={formValues.name || ""}
              />
              <label>CPF</label>
              <InputMask
                name="cpf"
                mask="999.999.999-99"
                value={formValues.cpf || ""}
                onChange={handleInputChange}
              ></InputMask>
            </>
          ) : null}
          {formValues.type === "Pessoa Jurídica" ? (
            <>
              <label>Razão Social</label>
              <input
                type="text"
                name="company_name"
                onChange={handleInputChange}
                value={formValues.company_name || ""}
              />
              <label>Nome Fantasia</label>
              <input
                type="text"
                name="business_name"
                onChange={handleInputChange}
                value={formValues.business_name || ""}
              />
              <label>CNPJ</label>
              <InputMask
                name="cnpj"
                mask="99.999.999/9999-99"
                value={formValues.cnpj || ""}
                onChange={handleInputChange}
              ></InputMask>
              <label>Inscrição Estadual</label>
              <input
                type="text"
                name="state_registration"
                onChange={handleInputChange}
                value={formValues.state_registration || ""}
              />
              <label>Inscrição Municipal</label>
              <input
                type="text"
                name="municipal_registration"
                onChange={handleInputChange}
                value={formValues.municipal_registration || ""}
              />
            </>
          ) : null}
          <label>E-mail</label>
          <input
            type="email"
            name="email"
            onChange={handleInputChange}
            value={formValues.email || ""}
          />
          <label>Telefone</label>
          <input
            type="text"
            name="phone"
            onChange={handleInputChange}
            value={formValues.phone || ""}
          />

          <label className="new-advertiser-error-message">
            <p htmlFor="new-advertiser-error-message">{errorMessage}</p>
          </label>

          <div className="new-advertiser-button-row">
            <Button type="submit" text="Cadastrar" confirmType />
            <Button
              handleClick={"/advertisers"}
              text="Cancelar"
              confirmType={false}
            />
          </div>
        </form>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
