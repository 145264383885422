/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import "./index.css";
import AppContext from "../../../contexts/AppContext.js";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Header } from "../../../components/Header/index.jsx";
import { MainRaw } from "../../../components/MainRaw/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";
import Button from "../../../components/Button/index.jsx";
export function ListUsers() {
  const { currentUser, setCurrentUser } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userList = await api.get(`/users`);
        return setUsers(userList.data);
      } catch (err) {
        console.log(err.response.data.message || err.response.message);
        setErrorMessage(err.response.data.message || err.response.message);
      }
    }
    fetchUserData();
  }, [rerender]);

  const deleteHandler = async (user) => {
    if (
      window.confirm(`Tem certeza que deseja remover o usuário "${user.name}"?`)
    ) {
      try {
        await api.delete(`/users/${user.external_id}`);
        if (currentUser.external_id === user.external_id) {
          console.log(
            "Você deletou a conta do usuário que estava com sessão ativa"
          );
          await api.delete(`/sessions/`);
          setCurrentUser({});
          return navigate("/");
        }
        setRerender((rerender) => !rerender);
      } catch (err) {
        console.log(err.response.data.message);
        window.alert(`Ocorreu um erro ao deletar o usuário.`);
      }
    }
  };

  return (
    <>
      <Header></Header>
      <MainRaw>
        <div className="list-users">
          <div className="list-user-button-row">
            <Button
              text="Cadastrar"
              confirmType={false}
              handleClick={"/users/new"}
            />
          </div>
          <h4>Lista de usuários</h4>
          <label className="list-users-error-message">
            <p htmlFor="list-users-error-message">{errorMessage}</p>
          </label>
          <div className="list-users-table">
            <table>
              <thead>
                <tr>
                  <th className="list-users-name">Nome</th>
                  <th className="list-users-email">E-mail</th>
                  <th className="list-users-is-admin">Perfil</th>
                  <th className="list-users-is-active">Situação</th>
                  <th className="list-users-action">Editar</th>
                  <th className="list-users-action">Deletar</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td className="list-users-name">{user.name}</td>
                      <td className="list-users-email">{user.email}</td>
                      <td className="list-users-is-admin">
                        {user.is_admin ? "Administrador" : "Padrão"}
                      </td>
                      <td className="list-users-is-active">
                        <span
                          style={{
                            paddingBlock: 5,
                            width: "100%",
                          }}
                        >
                          {user.is_active ? "Ativo" : "Inativo"}
                        </span>
                      </td>
                      <td className="list-users-action">
                        <Link to={`/users/update/${user.external_id}`}>
                          <FontAwesomeIcon icon={faPen} />
                        </Link>
                      </td>
                      <td className="list-users-action">
                        <Link to={`/users`} onClick={() => deleteHandler(user)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
