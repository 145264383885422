/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import "./index.css";
import AppContext from "../../../contexts/AppContext.js";
import Button from "../../../components/Button/index.jsx";
import { useContext } from "react";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Header } from "../../../components/Header/index.jsx";
import { MainRaw } from "../../../components/MainRaw/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";
import { categories } from "../../../utils/categories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export function ListPublications() {
  const { currentUser, setCurrentUser } = useContext(AppContext);
  const [publications, setPublications] = useState([]);
  const [publicationsFiltered, setPublicationsFiltered] = useState([]);
  const [filter, setFilter] = useState({});

  const [errorMessage, setErrorMessage] = useState("");

  const [rerender, setRerender] = useState(false);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    async function fetchPublications() {
      try {
        const publicationList = await api.get(`/publications`);
        setPublicationsFiltered(publicationList.data);

        return setPublications(publicationList.data);
      } catch (err) {
        console.log(err.response.data.message || err.response.message);
        setErrorMessage(err.response.data.message || err.response.message);
      }
    }
    fetchPublications();
  }, [refetch]);

  useEffect(() => {
    let listPublications = publications.filter((pub) => {
      if (filter.category && pub.category !== filter.category) return false;
      if (filter.text && !pub.title.includes(filter.text)) return false;
      if (filter.onlyHighlights && !pub.is_highlight) return false;

      if (filter.date) {
        const filterDate = new Date(filter.date);

        const pubDate = new Date(pub.updated_at);
        if (pubDate.getDate() < filterDate.getDate() + 1) return false;
      }
      return true;
    });

    return setPublicationsFiltered(listPublications);
  }, [rerender]);

  const deleteHandler = async (publication) => {
    if (
      window.confirm(
        `Tem certeza que deseja remover a publicação "${publication.title}"?`
      )
    ) {
      try {
        await api.delete(`/publications/${publication.external_id}`);

        setRefetch((refetch) => !refetch);
      } catch (err) {
        console.log(err.response.data.message);
        window.alert(`Ocorreu um erro ao deletar a publicação.`);
      }
    }
  };

  return (
    <>
      <Header></Header>
      <MainRaw>
        <div className="list-publications">
          <div className="list-publications-button-row">
            <Button
              text="Cadastrar"
              confirmType={false}
              handleClick={"/publications/new"}
            />
          </div>
          <h4>Lista de Publicações</h4>
          <label className="list-publications-error-message">
            <p htmlFor="list-publications-error-message">{errorMessage}</p>
          </label>

          <div className="filter-publications">
            <h5>Filtros:</h5>
            <Button
              handleClick={(e) => {
                setFilter({});
                setRerender((rerender) => !rerender);
              }}
              text="Mostrar Todos"
              confirmType
              style={{
                width: "fit-content",
              }}
            />

            <label htmlFor="filter-highlights">
              Somente destaques
              <input
                type="checkbox"
                checked={!!filter.onlyHighlights}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    onlyHighlights: e.target.checked,
                  });
                  setRerender((rerender) => !rerender);
                }}
              />
            </label>
            <label htmlFor="filter-by-category">
              Categoria
              <select
                id="filter-by-category"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    category: e.target.value,
                  });
                  setRerender((rerender) => !rerender);
                }}
                value={filter.category || ""}
              >
                <option></option>
                {categories.map((category, i) => (
                  <option key={i} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="filter-by-date">
              Data Base
              <input
                id="filter-by-date"
                type="date"
                value={filter.date || ""}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    date: e.currentTarget.value,
                    text: "",
                  });
                  setRerender((rerender) => !rerender);
                }}
              />
            </label>
            <label className="label-filter-search">
              <FontAwesomeIcon icon={faSearch} />
              <input
                type="input"
                value={filter.text || ""}
                placeholder="Digite palavras que estejam incluídas no título"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    text: e.target.value,
                  });
                  setRerender((rerender) => !rerender);
                }}
              />
            </label>
          </div>
          <div className="list-publications-table">
            <table>
              <thead>
                <tr>
                  <th className="list-publications-title">Título</th>
                  <th className="list-publications-category">Categoria</th>
                  <th className="list-publications-highlight">Destaque</th>
                  <th className="list-publications-date">Data de publicação</th>
                  <th className="list-publications-actions">Editar</th>
                  <th className="list-publications-actions">Deletar</th>
                </tr>
              </thead>

              <tbody>
                {publicationsFiltered.map((publication, index) => {
                  return (
                    <tr key={index}>
                      <td
                        className="list-publications-title"
                        title={publication?.title}
                      >
                        <span>{publication?.title}</span>
                      </td>
                      <td className="list-publications-category">
                        {publication.category}
                      </td>
                      <td className="list-publications-highlight">
                        {publication.is_highlight ? "Sim" : "Não"}
                      </td>
                      <td className="list-publications-date">
                        {new Date(publication.updated_at).toLocaleString()}
                      </td>
                      <td className="list-publications-actions">
                        <Link
                          to={`/publications/update/${publication.external_id}`}
                        >
                          <FontAwesomeIcon className="icon-edit" icon={faPen} />
                        </Link>
                      </td>
                      <td className="list-publications-actions">
                        <Link
                          to={`/publications`}
                          onClick={() => deleteHandler(publication)}
                        >
                          <FontAwesomeIcon
                            className="icon-delete"
                            icon={faTrash}
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
