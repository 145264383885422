/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/index.jsx";
import api from "../../../services/api.js";
import "./styles.css";
import { Header } from "../../../components/Header/index.jsx";
import { MainRaw } from "../../../components/MainRaw/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";

export function NewUser() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    isAdmin: false,
    isActive: true,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (event) => {
    setErrorMessage("");
    const { name, value, type, checked } = event.target;
    const isCheckbox = type === "checkbox";
    setFormValues({
      ...formValues,
      [name]: isCheckbox ? checked : value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    try {
      await api.post(`/users`, data);
      navigate("/users");
    } catch (err) {
      console.log(err.response.data.message || err.response.message);
      setErrorMessage(err.response.data.message || err.response.message);
    }
  };

  return (
    <>
      <Header></Header>
      <MainRaw>
        <form className="new-user" autoComplete="off" onSubmit={handleSubmit}>
          <h4>Cadastrar Usuário</h4>

          <label htmlFor="new-user-name">Nome</label>
          <input
            type="text"
            name="name"
            id="new-user-name"
            onChange={handleInputChange}
            value={formValues.name || ""}
          />

          <label htmlFor="new-user-email">E-mail</label>
          <input
            type="email"
            name="email"
            id="new-user-email"
            onChange={handleInputChange}
            value={formValues.email || ""}
          />

          <label htmlFor="new-user-password">Senha</label>
          <input
            type="password"
            name="password"
            id="new-user-password"
            onChange={handleInputChange}
            value={formValues.password || ""}
          />

          <label htmlFor="new-user-is-admin">Perfil</label>
          <div id="checkbox-profile">
            <input
              type="checkbox"
              name="isAdmin"
              id="new-user-is-admin"
              onChange={handleInputChange}
              value={formValues.isAdmin}
              checked={formValues.isAdmin}
            />
            Administrador
          </div>

          <label htmlFor="new-user-is-active">Situação</label>
          <div>
            <input
              type="checkbox"
              name="isActive"
              id="new-user-is-active"
              onChange={handleInputChange}
              value={formValues.isActive}
              checked={formValues.isActive}
            />
            Ativo
          </div>
          <label className="new-user-error-message">
            <p htmlFor="new-user-error-message">{errorMessage}</p>
          </label>

          <div className="new-user-button-row">
            <Button type="submit" text="Cadastrar" confirmType />
            <Button
              handleClick={"/users"}
              text="Cancelar"
              confirmType={false}
            />
          </div>
        </form>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
