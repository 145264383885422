/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button/index.jsx";
import api from "../../../services/api.js";
import "./index.css";
import AppContext from "../../../contexts/AppContext.js";
import { Header } from "../../../components/Header/index.jsx";
import { MainRaw } from "../../../components/MainRaw/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";

export function UpdateUser() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    oldPassword: "",
    isAdmin: false,
    isActive: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const { external_user_id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { currentUser, setCurrentUser } = useContext(AppContext);

  useEffect(() => {
    async function fetchData() {
      if (!external_user_id) {
        let { name, email, isAdmin, isActive } = currentUser;
        return setFormValues({
          ...formValues,
          name: name,
          email: email,
          oldPassword: "",
          isAdmin: isAdmin,
          isActive: isActive,
        });
      }
      try {
        await api.get(`/users/${external_user_id}`).then((user) => {
          let { name, email, is_admin, is_active } = user.data;
          return setFormValues({
            ...formValues,
            name: name,
            email: email,
            oldPassword: "",
            isAdmin: is_admin,
            isActive: is_active,
          });
        });
      } catch (err) {
        console.log(err.response.data.message);
        setErrorMessage(err.response.data.message);
      }
    }
    fetchData();
  }, [currentUser, external_user_id]);
  const handleInputChange = (event) => {
    setErrorMessage("");
    const { name, value, type, checked } = event.target;
    const isCheckbox = type === "checkbox";
    setFormValues({
      ...formValues,
      [name]: isCheckbox ? checked : value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    try {
      await api.put(
        `/users/${external_user_id || currentUser.external_id}`,
        data
      );

      navigate("/users");
    } catch (err) {
      console.log(err.response.data.message || err.response.message);
      setErrorMessage(err.response.data.message || err.response.message);
    }
  };
  return (
    <>
      <Header></Header>
      <MainRaw>
        <form
          className="update-user"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <h4>Atualizar Usuário</h4>

          <label htmlFor="label-email">Nome</label>
          <input
            type="text"
            name="name"
            id="update-user-name"
            onChange={handleInputChange}
            value={formValues.name || ""}
          />

          <label htmlFor="label-email">E-mail</label>
          <input
            type="email"
            name="email"
            id="update-user-email"
            onChange={handleInputChange}
            value={formValues.email || ""}
          />

          <label htmlFor="label-old-password">Senha atual</label>
          <input
            type="password"
            name="oldPassword"
            id="update-user-old-password"
            onChange={handleInputChange}
            value={formValues.oldPassword || ""}
          />
          <label htmlFor="label-new-password">Nova senha</label>
          <input
            type="password"
            name="newPassword"
            id="update-user-new-password"
            onChange={handleInputChange}
            value={formValues.newPassword || ""}
          />
          <div
            className={
              currentUser.isAdmin
                ? "update-user-label-profile"
                : "hidden-profile"
            }
          >
            <label htmlFor="label-perfil">Perfil</label>
            <div id="checkbox-profile">
              <label htmlFor="update-user-is-admin">
                <input
                  type="checkbox"
                  name="isAdmin"
                  id="update-user-is-admin"
                  onChange={handleInputChange}
                  value={formValues.isAdmin}
                  checked={formValues.isAdmin}
                />
                Administrador
              </label>
            </div>

            <label htmlFor="label-active">Situação</label>
            <div>
              <label htmlFor="update-user-is-active">
                <input
                  type="checkbox"
                  name="isActive"
                  id="update-user-is-active"
                  onChange={handleInputChange}
                  value={formValues.isActive}
                  checked={formValues.isActive}
                />
                Ativo
              </label>
            </div>
          </div>
          <label className="update-user-error-message">
            <p htmlFor="update-user-error-message">{errorMessage}</p>
          </label>

          <div className="update-user-button-row">
            <Button type="submit" text="Salvar" confirmType />
            <Button
              handleClick={"/users"}
              text="Cancelar"
              confirmType={false}
            />
          </div>
        </form>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
