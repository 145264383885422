import { Header } from "../../components/Header";
import { Main } from "../../components/Main";
import { Footer } from "../../components/Footer";
import "./index.css";
export function About() {
  return (
    <>
      <Header></Header>
      <Main>
        <article className="about">
          <h3>Sobre</h3>
          <p>
            O portal de notícias Caderno 3 surgiu com o intuito de apresentar
            para a população de Caçapava do Sul e região os fatos e notícias
            mais relevantes da atualidade.
          </p>
          <p>
            Temos como valores a integridade, a veracidade e a confiança,
            publicando sempre notícias de forma imparcial, ouvindo as diversas
            partes envolvidas.
          </p>
          <p>
            Usar a lei de imprensa, em todos os sentidos, pois é nosso dever
            garantir aos cidadãos a informação mais precisa e objetiva possível.
            Todas as informações aqui postadas, são revisadas e embasada em
            informações de fontes legítimas.
          </p>
          <p>
            Além disso, os profissionais ouvidos são pessoas que possuem
            formação na área de conhecimento sobre o assunto tratado.
          </p>
        </article>
      </Main>
      <Footer></Footer>
    </>
  );
}
