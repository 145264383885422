import { Route, Routes, BrowserRouter, useNavigate } from "react-router-dom";
import "./styles/styles.css";

import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import api from "./services/api";
import AppContext from "./contexts/AppContext.js";

import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Login } from "./pages/Login";
import { NotFoundPage } from "./pages/NotFoundPage";

//USERS
import { NewUser } from "./pages/Users/NewUser";
import { ListUsers } from "./pages/Users/ListUsers";
import { UpdateUser } from "./pages/Users/UpdateUser";

//PUBLICATIONS
import { NewPublication } from "./pages/Publications/NewPublication";
import { ListPublications } from "./pages/Publications/ListPublications";
import { ListPublicationsByCategory } from "./pages/Publications/ListPublicationsByCategory";
import { UpdatePublication } from "./pages/Publications/UpdatePublication";
import { ShowPublication } from "./pages/Publications/ShowPublication";

//ADVERTISERS
import { NewAdvertiser } from "./pages/Advertisers/NewAdvertiser";
import { ListAdvertisers } from "./pages/Advertisers/ListAdvertisers";
import { UpdateAdvertiser } from "./pages/Advertisers/UpdateAdvertiser";

//ADS
import { ListAds } from "./pages/Ads/ListAds";
import { NewAd } from "./pages/Ads/NewAd";
import { UpdateAd } from "./pages/Ads/UpdateAd";

function App() {
  const [cookies] = useCookies();
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    async function fetchUserData() {
      if (!currentUser.name && cookies.user_id) {
        try {
          await api.get(`/users/${cookies.user_id}`).then((user) => {
            const { external_id, name, email, is_admin } = user.data;
            setCurrentUser({
              external_id,
              name,
              email,
              isAdmin: is_admin,
            });
          });
        } catch (err) {
          err.response.data.message
            ? console.log(err.response.data.message)
            : console.log("Não foi possível acessar o servidor. Erro: " + err);
        }
      }
    }
    fetchUserData();
  }, [cookies.user_id, currentUser.name]);

  return (
    <AppContext.Provider
      value={{
        currentUser,
        setCurrentUser,
      }}
    >
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route element={<Home />} path="/" exact />
            <Route
              element={<ListPublicationsByCategory />}
              path="/publications/categories/:category"
            />
            <Route element={<About />} path="/about" />
            <Route element={<Contact />} path="/contact" />

            <Route path="/login" element={<Login />} />

            <Route path="/publications/">
              <Route
                index
                element={
                  currentUser.external_id ? (
                    <ListPublications />
                  ) : (
                    <NotFoundPage />
                  )
                }
              />

              <Route
                path=":external_publication_id"
                element={<ShowPublication />}
              />
            </Route>

            <Route element={<NotFoundPage />} path="*" />

            {/* ÁREA ADMINISTRATIVA */}
            {currentUser.external_id ? (
              <>
                {currentUser.isAdmin ? (
                  <>
                    <Route path="/users" element={<ListUsers />} />
                    <Route path="/users/new" element={<NewUser />} />
                    <Route
                      path="/advertisers/new"
                      element={<NewAdvertiser />}
                    />
                    <Route path="/advertisers" element={<ListAdvertisers />} />
                    <Route
                      path="/advertisers/update/:external_advertiser_id"
                      element={<UpdateAdvertiser />}
                    />
                    <Route path="/ads" element={<ListAds />} />
                    <Route path="/ads/new" element={<NewAd />} />
                    <Route
                      path="/ads/update/:external_ad_id"
                      element={<UpdateAd />}
                    />
                  </>
                ) : null}
                <Route element={<NewPublication />} path="/publications/new" />
                <Route
                  element={<UpdatePublication />}
                  path="/publications/update/:external_publication_id"
                />

                <Route path="/users/update/">
                  <Route index element={<UpdateUser />} />

                  <Route path=":external_user_id" element={<UpdateUser />} />
                </Route>
              </>
            ) : null}
          </Routes>
        </BrowserRouter>
      </div>
    </AppContext.Provider>
  );
}

export default App;
