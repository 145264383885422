import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useContext } from "react";
import AppContext from "../../contexts/AppContext.js";
import api from "../../services/api";
import { useCookies } from "react-cookie";
import { categories } from "../../utils/categories";

export function Header(props) {
  const [cookies, , removeCookie] = useCookies();
  const [icon, setIcon] = useState(faBars);
  const [menuChecked, setMenuChecked] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AppContext);
  const [rerender, setRerender] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (menuChecked) {
      setIcon(faXmark);
    } else {
      setIcon(faBars);
    }
  }, [menuChecked, currentUser]);

  const logoutHandler = async () => {
    if (window.confirm(`Tem certeza que deseja sair?`)) {
      try {
        await api.delete(`/sessions`);
        setCurrentUser({});
        removeCookie("user_id", {
          domain: "caderno3.com.br",
          path: "/",
        });
        removeCookie("token", { domain: "caderno3.com.br", path: "/" });
        setRerender((rerender) => !rerender);
        return navigate("/");
      } catch (err) {
        console.log(err.response.data.message);
        window.alert(`Ocorreu um erro ao sair da aplicação.`);
      }
    }
  };

  return (
    <header className="header">
      <Link to="/">
        <img src={logo} className="header-logo" />
      </Link>
      <input
        type="checkbox"
        id="toggler"
        onClick={() => setMenuChecked((menuChecked) => !menuChecked)}
      ></input>

      <label htmlFor="toggler">
        <FontAwesomeIcon
          className="icon-edit"
          icon={icon}
          style={{ fontSize: "1.5rem" }}
        />
      </label>
      <nav>
        <ul className="header-main-menu">
          <li>
            <Link to="/">Início</Link>
          </li>
          <li>
            <Link to="/about">Sobre Nós</Link>
          </li>
          <li>
            <Link to="/contact">Contato</Link>
          </li>
        </ul>
      </nav>

      <nav>
        <ul className="header-categories-menu">
          {categories.map((category, i) => (
            <li key={i}>
              <Link to={`/publications/categories/${category}`}>
                {category}
              </Link>
            </li>
          ))}
        </ul>
        {currentUser.external_id && (
          <ul className="header-admin-menu">
            {currentUser.isAdmin && (
              <li>
                <Link to="/users">Usuários</Link>
              </li>
            )}

            <li>
              <Link to="/publications">Publicações</Link>
            </li>
            <li>
              <Link to="/advertisers">Anunciantes</Link>
            </li>
            <li>
              <Link to="/ads">Anúncios</Link>
            </li>
            <li>
              <Link to={`/users/update/${currentUser.external_id}`}>
                Minha Conta
              </Link>
            </li>
            <li>
              <Link onClick={logoutHandler}>Sair</Link>
            </li>
          </ul>
        )}
      </nav>
    </header>
  );
}
