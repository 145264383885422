/* eslint-disable import/no-anonymous-default-export */
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/index.jsx";
import api from "../../../services/api.js";
import "./styles.css";
import { convertToBase64 } from "../../../utils/convertToBase64.js";
import { Header } from "../../../components/Header/index.jsx";
import { MainRaw } from "../../../components/MainRaw/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [
      { indent: "-1" },
      { indent: "+1" },
      { align: ["justify", "right", "center", ""] },
    ],
    ["clean"],
  ],
};

export function NewPublication() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    is_highlight: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const primaryImageInput = useRef(null);
  const secondaryImageInput = useRef(null);

  const handleInputChange = async (event) => {
    setErrorMessage("");
    const { name, value, type, checked, files } = event.target;
    if (type === "checkbox") {
      return setFormValues({
        ...formValues,
        [name]: checked,
      });
    }
    if (type === "file") {
      const base64 = await convertToBase64(files[0]);
      return setFormValues({
        ...formValues,
        [name]: base64,
      });
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { primaryImage, secondaryImage, ...data } = formValues;

    data.images = [primaryImage, secondaryImage].filter((item) => !!item);
    data.youtube_video_id = data.youtube_video_id?.split("=")[1];

    try {
      console.log("data");
      console.log(data);
      await api.post(`/publications`, data);
      navigate("/publications");
    } catch (err) {
      console.log(err.response.data.message || err.response.message);
      setErrorMessage(err.response.data.message || err.response.message);
    }
  };
  return (
    <>
      <Header></Header>
      <MainRaw>
        <form
          className="new-publication"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <h4>Cadastrar Publicação</h4>

          <label htmlFor="new-publication-title">Título</label>
          <input
            type="text"
            name="title"
            id="new-publication-title"
            onChange={handleInputChange}
            value={formValues.title || ""}
          />

          <label htmlFor="new-publication-content">Conteúdo</label>

          <ReactQuill
            id="new-publication-content"
            modules={modules}
            theme="snow"
            onChange={(content) =>
              setFormValues({
                ...formValues,
                content,
              })
            }
            value={formValues.content || ""}
          />
          <label htmlFor="new-publication-category">Categoria</label>
          <select
            name="category"
            id="new-publication-category"
            onChange={handleInputChange}
            value={formValues.category || ""}
          >
            <option>GERAL</option>
            <option>POLÍTICA</option>
            <option>EDUCAÇÃO</option>
            <option>ECONOMIA</option>
            <option>ESPORTE</option>
            <option>SEGURANÇA</option>
            <option>SAÚDE</option>
            <option>OBITUÁRIO</option>
          </select>

          <label>Situação</label>
          <div id="checkbox-highlight">
            <input
              type="checkbox"
              name="is_highlight"
              id="new-publication-is-highlight"
              onChange={handleInputChange}
              value={formValues.is_highlight}
              checked={formValues.is_highlight}
            />
            <label htmlFor="new-publication-is-highlight">Destaque</label>
          </div>
          <label htmlFor="new-publication-primary-image">
            Imagem Principal
          </label>
          {formValues?.primaryImage && (
            <div className="new-publication-images">
              <img src={formValues.primaryImage} alt="" />
            </div>
          )}
          {formValues?.primaryImage && (
            <button
              onClick={() => {
                primaryImageInput.current.value = null;
                setFormValues({
                  ...formValues,
                  primaryImage: null,
                });
              }}
              style={{
                fontSize: "0.96rem",
                marginBottom: "10px",
              }}
            >
              Remover imagem
            </button>
          )}
          <input
            style={{ display: formValues?.primaryImage ? "none" : null }}
            onChange={handleInputChange}
            name="primaryImage"
            ref={primaryImageInput}
            type="file"
            id="new-publication-primary-image"
            accept=".jpg, .jpeg"
          />
          <label htmlFor="new-publication-secondary-image">
            Imagem Secundária
          </label>
          {formValues?.secondaryImage && (
            <div className="new-publication-images">
              <img src={formValues?.secondaryImage} alt="" />
            </div>
          )}
          {formValues?.secondaryImage && (
            <button
              onClick={() => {
                secondaryImageInput.current.value = null;
                setFormValues({
                  ...formValues,
                  secondaryImage: null,
                });
              }}
              style={{
                fontSize: "0.96rem",
                marginBottom: "10px",
              }}
            >
              Remover imagem
            </button>
          )}
          <input
            style={{
              display: formValues?.secondaryImage ? "none" : null,
            }}
            onChange={handleInputChange}
            name="secondaryImage"
            ref={secondaryImageInput}
            type="file"
            id="new-publication-secondary-image"
            accept=".jpg, .jpeg"
          />

          <label htmlFor="new-publication-youtube-link">
            Link do vídeo do Youtube
          </label>
          <input
            type="text"
            name="youtube_video_id"
            id="new-publication-youtube-link"
            onChange={handleInputChange}
            value={formValues.youtube_video_id || ""}
          />

          <label className="new-publication-error-message">
            <p htmlFor="new-publication-error-message">{errorMessage}</p>
          </label>

          <div className="new-publication-button-row">
            <Button type="submit" text="Cadastrar" confirmType />
            <Button
              handleClick={"/publications"}
              text="Cancelar"
              confirmType={false}
            />
          </div>
        </form>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
