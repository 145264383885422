import axios from "axios";
import jsCookie from "js-cookie";
const api = axios.create({
  withCredentials: true,
  // baseURL: "http://localhost:3333", //MODO DEV
  baseURL: "https://api.caderno3.com.br", //MODO PROD
});

api.interceptors.request.use(function (config) {
  const token = jsCookie.get("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default api;
