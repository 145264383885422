/* eslint-disable import/no-anonymous-default-export */
import { useState, useContext } from "react";
//import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/index.jsx";
import api from "../../services/api";
import "./index.css";
import { useCookies } from "react-cookie";
import AppContext from "../../contexts/AppContext.js";
import { Header } from "../../components/Header/index.jsx";
import { MainRaw } from "../../components/MainRaw/index.jsx";
import { Footer } from "../../components/Footer/index.jsx";

export function Login() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [cookies] = useCookies();
  const [emailForm, setEmailForm] = useState("");
  const [passwordForm, setPasswordForm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const { currentUser, setCurrentUser } = useContext(AppContext);

  const handleInputChange = (event) => {
    setErrorMessage("");
    switch (event.target.name) {
      case "login-email":
        setEmailForm(event.target.value);
        break;
      case "login-password":
        setPasswordForm(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const session = await api.post("/sessions", {
        email: emailForm,
        password: passwordForm,
      });
      const { external_id, name, email, isAdmin } = session.data.user;
      api.defaults.headers.common["Authorization"] = `Bearer ${cookies.token}`;

      setCurrentUser({ external_id, name, email, isAdmin });

      return navigate("/publications");
    } catch (err) {
      console.log(err.response.data.message || err.response.message);
      setErrorMessage(err.response.data.message || err.response.message);
    }
  };

  return (
    <>
      <Header></Header>
      <MainRaw>
        <form className="login">
          <h4>Entrar</h4>
          <label htmlFor="label-email">E-mail</label>
          <input
            type="email"
            name="login-email"
            id="login-email"
            onChange={handleInputChange}
          />
          <label htmlFor="label-password">Senha</label>
          <input
            type="password"
            name="login-password"
            id="login-password"
            onChange={handleInputChange}
          />
          <label htmlFor="label-error"> </label>
          <p htmlFor="login-error-message" className="login-error-message">
            {errorMessage}
          </p>

          <div className="login-button-row">
            <Button handleClick={handleSubmit} text="Entrar" confirmType />
            <Button handleClick={"/"} text="Voltar" confirmType={false} />
          </div>
        </form>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
