/* eslint-disable jsx-a11y/anchor-is-valid */
import { Header } from "../../../components/Header/index";
import { Main } from "../../../components/Main/index";
import { Footer } from "../../../components/Footer/index";
import "./index.css";
import { Container, Row, Col } from "react-grid-system";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import "./index.css";
import api from "../../../services/api";
import { CardPublication } from "../../../components/CardPublication";

export function ListPublicationsByCategory() {
  const [publications, setPublications] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState([]);
  // const [loading, setLoading] = useState(true);
  const { category } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPublicationsByCategory() {
      try {
        const totalNumber = await api.get(
          `/publications/categories/total-number/${category}`
        );
        const publicationList = await api.get(
          `/publications/categories/?category=${category}&page=${pageNumber}`
        );
        // setTotalNumber(parseInt(totalNumber?.data[0]?.count));
        const pagesList = [];
        for (let i = 1; i <= Math.ceil(totalNumber.data[0].count / 12); i++) {
          pagesList.push(i);
        }
        setPages(pagesList);
        setPublications(publicationList.data);
      } catch (err) {
        console.log(err);
        console.log(err.response.data.message || err.response.message);
      }
    }
    fetchPublicationsByCategory();
  }, [category, pageNumber]);

  return (
    <>
      <Header></Header>
      <Main>
        <div className="cards-container">
          <div className="header-cards-container">
            <h3>{category}</h3>
          </div>
          <Container>
            {publications.length ? (
              <>
                <Row gutterWidth={70}>
                  {publications.map((publication, index, lista) => (
                    <Col xs={12} lg={lista.length < 2 ? 12 : 6} key={index}>
                      <CardPublication
                        publication={publication}
                        hideCategory
                      ></CardPublication>
                    </Col>
                  ))}
                </Row>
                <Row justify="center">
                  <div className="list-publication-by-category-pages">
                    <a
                      role="buttom"
                      onClick={() => {
                        if (pageNumber > 1) setPageNumber(pageNumber - 1);
                      }}
                    >
                      {"<"}
                    </a>
                  </div>

                  {pages.map((page) => (
                    <div
                      style={{
                        boxShadow:
                          page === pageNumber ? "#111 2px 3px 5px" : null,
                      }}
                      key={page}
                      className="list-publication-by-category-pages"
                    >
                      <a role="buttom" onClick={() => setPageNumber(page)}>
                        {page}
                      </a>
                    </div>
                  ))}

                  <div className="list-publication-by-category-pages">
                    <a
                      role="buttom"
                      onClick={() => {
                        if (pageNumber < pages[pages.length - 1])
                          setPageNumber(pageNumber + 1);
                      }}
                    >
                      {">"}
                    </a>
                  </div>
                </Row>
              </>
            ) : (
              <p>Ainda não há publicações nesta categoria</p>
            )}
          </Container>
        </div>
      </Main>
      <Footer></Footer>
    </>
  );
}
