/* eslint-disable import/no-anonymous-default-export */
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/index.jsx";
import api from "../../../services/api.js";
import "./styles.css";
import { convertToBase64 } from "../../../utils/convertToBase64.js";
import { Header } from "../../../components/Header/index.jsx";
import { MainRaw } from "../../../components/MainRaw/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";
import InputMask from "react-input-mask";
import CurrencyInput from "react-currency-input-field";
import AppContext from "../../../contexts/AppContext.js";
import { useContext } from "react";
export function NewAd() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({});
  const [sequences, setSequences] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const desktopImageInput = useRef(null);
  const mobileImageInput = useRef(null);
  const { currentUser, setCurrentUser } = useContext(AppContext);
  const [positions, setPositions] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    async function fetchAds() {
      try {
        const advertiserList = await api.get(`/advertisers`);
        setAdvertisers(advertiserList.data);
        const positionList = await api.get(`/ads/positions`);
        setPositions(positionList.data);
      } catch (err) {
        console.log(err.response.data.message || err.response.message);
        setErrorMessage(err.response.data.message || err.response.message);
      }
    }
    fetchAds();
  }, [refetch]);
  const handleInputChange = async (event) => {
    setErrorMessage("");
    const { name, value, type, checked, files } = event.target;
    if (type === "checkbox") {
      return setFormValues({
        ...formValues,
        [name]: checked,
      });
    }
    if (type === "file") {
      const base64 = await convertToBase64(files[0]);
      let imageName = "";
      if (name === "desktopImage") {
        imageName = "desktop_image";
      }
      if (name === "mobileImage") {
        imageName = "mobile_image";
      }
      return setFormValues({
        ...formValues,
        [name]: files[0],
        [imageName]: base64,
      });
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { desktopImage, mobileImage, ...data } = formValues;
    data.price = data.price ? data.price.replace(",", ".") : null;
    if ((sequences || []).find((seq) => seq == data.sequence)) {
      setFormValues({ ...formValues, sequence: null });
      return setErrorMessage(
        "Sequência já utilizada para a posição selecionada"
      );
    }
    try {
      await api.post(`/ads`, data);
      navigate("/ads");
    } catch (err) {
      console.log(err.response.data.message || err.response.message);
      setErrorMessage(err.response.data.message || err.response.message);
    }
  };
  return (
    <>
      <Header></Header>
      <MainRaw>
        <form className="new-ad" autoComplete="off" onSubmit={handleSubmit}>
          <h4>Cadastrar Anúncio</h4>
          <label>Descrição do Anúncio</label>
          <input
            type="text"
            name="description"
            placeholder="Informe o texto que deve aparecer ao passar o mouse sobre o anúncio"
            onChange={handleInputChange}
            value={formValues.description || ""}
          />
          <label className="new-ad-select">Anunciante </label>
          <select
            name="advertiser_id"
            onChange={handleInputChange}
            value={formValues.advertiser_id || ""}
          >
            <option>{null}</option>
            {advertisers.map((advertiser) => {
              return (
                <option key={advertiser.id} value={advertiser.id}>
                  {advertiser.name || advertiser.company_name}
                </option>
              );
            })}
          </select>

          <label className="new-ad-select">Posição</label>
          <select
            name="position_id"
            onChange={(e) => {
              const selectedPosition =
                positions.find((pos) => pos.id == e.target.value) || {};
              const sequencesFiltered = selectedPosition.sequences[0]
                ? selectedPosition.sequences
                : null;
              setSequences(sequencesFiltered);
              handleInputChange(e);
            }}
            value={formValues.position_id || ""}
          >
            <option>{null}</option>
            {positions.map((position) => {
              return (
                <option key={position.id} value={position.id}>
                  {position.name}
                </option>
              );
            })}
          </select>
          <label>Sequência</label>
          <InputMask
            name="sequence"
            maskChar={null}
            placeholder={
              formValues.position_id
                ? sequences
                  ? `Sequências já utilizadas para a posição selecionada: ${sequences}`
                  : "Todas as sequências estão disponíveis para a posição selecionada"
                : "Selecione a posição para ver as sequências já utilizadas"
            }
            mask="99"
            value={formValues.sequence || ""}
            onChange={handleInputChange}
          ></InputMask>
          <label>Preço</label>
          <CurrencyInput
            prefix="R$ "
            name="price"
            value={formValues.price || ""}
            onValueChange={(value, name) => {
              const e = { target: { value, name } };
              handleInputChange(e);
            }}
            allowNegativeValue={false}
          ></CurrencyInput>

          <label>Descrição da Forma de Pagamento</label>
          <input
            type="text"
            name="description_payment_method"
            placeholder="Informe como o pagamento será realizado"
            onChange={handleInputChange}
            value={formValues.description_payment_method || ""}
          />
          <label>Data de Validade</label>
          <input
            type="date"
            name="expiration_date"
            min={new Date().toISOString().substring(0, 10)}
            onChange={handleInputChange}
            value={formValues.expiration_date || ""}
          />
          <label>Link do Anúncio</label>
          <input
            type="text"
            name="link"
            placeholder="Informe o link para onde o usuário será redirecionado ao clicar no anúncio"
            onChange={handleInputChange}
            value={formValues.link || ""}
          />
          <label htmlFor="new-ad-desktop-image">Imagem Desktop</label>
          {formValues?.desktop_image && (
            <div className="new-ad-images">
              <img src={formValues.desktop_image} alt="" />
            </div>
          )}
          {formValues?.desktop_image && (
            <button
              onClick={() => {
                desktopImageInput.current.value = null;
                setFormValues({
                  ...formValues,
                  desktop_image: null,
                  desktopImage: null,
                });
              }}
              style={{
                fontSize: "0.96rem",
                marginBottom: "10px",
              }}
            >
              Remover imagem
            </button>
          )}
          <input
            style={{ display: formValues?.desktop_image ? "none" : null }}
            onChange={handleInputChange}
            name="desktopImage"
            ref={desktopImageInput}
            type="file"
            id="new-ad-desktop-image"
          />
          <label htmlFor="new-ad-mobile-image">Imagem Mobile</label>
          {formValues?.mobile_image && (
            <div className="new-ad-images">
              <img src={formValues?.mobile_image} alt="" />
            </div>
          )}
          {formValues?.mobile_image && (
            <button
              onClick={() => {
                mobileImageInput.current.value = null;
                setFormValues({
                  ...formValues,
                  mobile_image: null,
                  mobileImage: null,
                });
              }}
              style={{
                fontSize: "0.96rem",
                marginBottom: "10px",
              }}
            >
              Remover imagem
            </button>
          )}
          <input
            style={{ display: formValues?.mobile_image ? "none" : null }}
            onChange={handleInputChange}
            name="mobileImage"
            ref={mobileImageInput}
            type="file"
            id="new-ad-mobile-image"
          />

          <label className="new-ad-error-message">
            <p htmlFor="new-ad-error-message">{errorMessage}</p>
          </label>

          <div className="new-ad-button-row">
            <Button type="submit" text="Cadastrar" confirmType />
            <Button handleClick={"/ads"} text="Cancelar" confirmType={false} />
          </div>
        </form>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
