/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button/index.jsx";
import api from "../../../services/api.js";
import "./styles.css";
import { convertToBase64 } from "../../../utils/convertToBase64.js";
import { Header } from "../../../components/Header/index.jsx";
import { MainRaw } from "../../../components/MainRaw/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";
import noImageItem from "../../../assets/images/no-image-item.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [
      { indent: "-1" },
      { indent: "+1" },
      { align: ["justify", "right", "center", ""] },
    ],
    ["clean"],
  ],
};

export function UpdatePublication() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    is_highlight: false,
  });
  const primaryImageInput = useRef(null);
  const secondaryImageInput = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { external_publication_id } = useParams();

  const handleRichTextInput = async (content) => {
    if (formValues.title)
      return setFormValues({
        ...formValues,
        content: content,
      });
  };

  const handleInputChange = async (event) => {
    setErrorMessage("");
    const { name, value, type, checked, files } = event.target;
    if (type === "checkbox") {
      return setFormValues({
        ...formValues,
        [name]: checked,
      });
    }
    if (type === "file") {
      const base64 = await convertToBase64(files[0]);
      return setFormValues({
        ...formValues,
        [name]: base64,
      });
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { primaryImage, secondaryImage, ...data } = formValues;

    data.images = [primaryImage, secondaryImage].filter((item) => !!item);
    data.youtube_video_id = data.youtube_video_id?.split("=")[1];
    console.log(data);
    try {
      await api.put(`/publications/${external_publication_id}`, data);
      navigate("/publications");
    } catch (err) {
      console.log(err.response.data.message || err.response.message);
      setErrorMessage(err.response.data.message || err.response.message);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await api
          .get(`/publications/${external_publication_id}`)
          .then((publication) => {
            const {
              title,
              content,
              category,
              is_highlight,
              gdrive_image_ids,
              images,
              youtube_video_id,
            } = publication.data;

            return setFormValues({
              ...formValues,
              title,
              content,
              category,
              is_highlight: is_highlight,
              primaryImage: gdrive_image_ids[0]
                ? // ? `https://drive.google.com/uc?export=view&id=${gdrive_image_ids[0]}`
                  `https://drive.google.com/thumbnail?id=${gdrive_image_ids[0]}&sz=w1000`
                : images[0],
              secondaryImage: gdrive_image_ids[1]
                ? // ? `https://drive.google.com/uc?export=view&id=${gdrive_image_ids[1]}`
                  `https://drive.google.com/thumbnail?id=${gdrive_image_ids[1]}&sz=w1000`
                : images[1],
              youtube_video_id: youtube_video_id
                ? "https://www.youtube.com/watch?v=" + youtube_video_id
                : "",
            });
          });
      } catch (err) {
        console.log(err.response.data.message);
        setErrorMessage(err.response.data.message);
      }
    }
    fetchData();
  }, [external_publication_id]);
  return (
    <>
      <Header></Header>
      <MainRaw>
        <form
          className="update-publication"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <h4>Editar Publicação</h4>
          <label htmlFor="update-publication-title">Título</label>
          <input
            type="text"
            name="title"
            id="update-publication-title"
            onChange={handleInputChange}
            value={formValues.title || ""}
          />
          <label htmlFor="update-publication-content">Conteúdo</label>
          <ReactQuill
            id="update-publication-content"
            modules={modules}
            theme="snow"
            onChange={handleRichTextInput}
            value={formValues.content || ""}
          />
          <label htmlFor="update-publication-category">Categoria</label>
          <select
            name="category"
            id="update-publication-category"
            onChange={handleInputChange}
            value={formValues.category || ""}
          >
            <option>GERAL</option>
            <option>POLÍTICA</option>
            <option>EDUCAÇÃO</option>
            <option>ECONOMIA</option>
            <option>ESPORTE</option>
            <option>SEGURANÇA</option>
            <option>SAÚDE</option>
            <option>OBITUÁRIO</option>
          </select>
          <label htmlFor="label-highlight">Situação</label>
          <div id="checkbox-highlight">
            <input
              type="checkbox"
              name="is_highlight"
              id="update-publication-is-highlight"
              onChange={handleInputChange}
              value={formValues.is_highlight}
              checked={formValues.is_highlight}
            />
            Destaque
          </div>
          <label htmlFor="update-publication-primary-image">
            Imagem Principal
          </label>
          <div className="update-publication-images">
            <img src={formValues?.primaryImage || noImageItem} alt="Imagem" />
          </div>
          {formValues?.primaryImage && (
            <button
              onClick={() => {
                primaryImageInput.current.value = null;
                setFormValues({
                  ...formValues,
                  primaryImage: null,
                });
              }}
              style={{
                fontSize: "0.96rem",
                marginBottom: "10px",
              }}
            >
              Remover imagem
            </button>
          )}
          <input
            style={{
              display: formValues?.primaryImage ? "none" : null,
            }}
            onChange={handleInputChange}
            name="primaryImage"
            ref={primaryImageInput}
            type="file"
            id="update-publication-primary-image"
            accept=".jpg, .jpeg"
          />
          <label htmlFor="update-publication-secondary-image">
            Imagem Secundária
          </label>
          <div
            className="
update-publication-images"
          >
            <img src={formValues?.secondaryImage || noImageItem} alt="Imagem" />
          </div>
          {formValues?.secondaryImage && (
            <button
              onClick={() => {
                secondaryImageInput.current.value = null;
                setFormValues({
                  ...formValues,
                  secondaryImage: null,
                });
              }}
              style={{
                fontSize: "0.96rem",
                marginBottom: "10px",
              }}
            >
              Remover imagem
            </button>
          )}
          <input
            style={{
              display: formValues?.secondaryImage ? "none" : null,
            }}
            onChange={handleInputChange}
            name="secondaryImage"
            ref={secondaryImageInput}
            type="file"
            id="update-publication-secondary-image"
            accept=".jpg, .jpeg"
          />
          <label htmlFor="update-publication-youtube-link">
            Link do vídeo do Youtube
          </label>
          <input
            type="text"
            name="youtube_video_id"
            id="update-publication-youtube-link"
            onChange={handleInputChange}
            value={formValues.youtube_video_id || ""}
          />
          <label className="update-publication-error-message">
            <p htmlFor="update-publication-error-message">{errorMessage}</p>
          </label>
          <div className="update-publication-button-row">
            <Button type="submit" text="Salvar" confirmType />
            <Button
              handleClick={"/publications"}
              text="Cancelar"
              confirmType={false}
            />
          </div>
        </form>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
