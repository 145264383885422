import React, { useEffect, useState } from "react";
import { Fade } from "react-slideshow-image";
import { Link } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import "./index.css";

export function AdSlideShow({ publications, orientation, mode }) {
  return (
    <div className={`ad-slide-container-${orientation}`}>
      {publications.length ? (
        <Fade arrows={false}>
          {publications.map((publication, index) =>
            publication.link ? (
              <Link
                key={index}
                className="ad-slide-publication-link"
                to={publication.link}
                target="_blank"
              >
                <div
                  className={`ad-slideshow-image-${orientation}`}
                  title={publication?.description}
                >
                  <img
                    src={
                      mode === "desktop"
                        ? publication.desktop_image
                        : publication.mobile_image
                    }
                    alt={publication.description}
                  />
                </div>
              </Link>
            ) : (
              <div
                key={index}
                className={`ad-slideshow-image-${orientation}`}
                title={publication?.description}
              >
                <img
                  src={
                    mode === "desktop"
                      ? publication.desktop_image
                      : publication.mobile_image
                  }
                  alt={publication.description}
                />
              </div>
            )
          )}
        </Fade>
      ) : (
        <h4 style={{ paddingTop: 40, color: "#999" }}>Anuncie aqui</h4>
      )}
    </div>
  );
}
