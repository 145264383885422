import "./index.css";
import { Container, Row, Col } from "react-grid-system";
import publicidade_h from "../../assets/images/publicidade-h.jpg";
import anuncio_peixaria_e_emporio_brasil from "../../assets/images/anuncio_peixaria_e_emporio_brasil.jpg";
import anuncio_88_expofeira_agropecuaria from "../../assets/images/anuncio_88_expofeira_agropecuaria.png";
import { useState, useEffect } from "react";
import useFetch from "../../Hooks/useFetch";
import { AdSlideShow } from "../AdSlideShow";

export function Main(props) {
  //busca anuncios
  const url = "/ads";
  const { data, loading, error } = useFetch(url, []);
  const listaMobile = data.sort((adA, adB) => adA.sequence - adB.sequence);
  const horizontalSuperiorEsquerda = data.filter((ad) => ad.position_id == 1);
  const horizontalSuperiorCentral = data.filter((ad) => ad.position_id == 2);
  const horizontalSuperiorDireita = data.filter((ad) => ad.position_id == 3);
  const verticalSuperiorDireita = data.filter((ad) => ad.position_id == 4);
  const verticalInferiorDireita = data.filter((ad) => ad.position_id == 5);
  const horizontalInferiorEsquerda = data.filter((ad) => ad.position_id == 6);
  const horizontalInferiorCentral = data.filter((ad) => ad.position_id == 7);
  const horizontalInferiorDireita = data.filter((ad) => ad.position_id == 8);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <main className="page-main">
      <Container style={{ maxWidth: 1300 }}>
        {windowSize.width < 1000 ? null : (
          <Row>
            <Col xs={4}>
              <AdSlideShow
                publications={horizontalSuperiorEsquerda}
                orientation="horizontal"
                mode="desktop"
              />
            </Col>
            <Col xs={4}>
              <AdSlideShow
                publications={horizontalSuperiorCentral}
                orientation="horizontal"
                mode="desktop"
              />
            </Col>
            <Col xs={4}>
              <AdSlideShow
                publications={horizontalSuperiorDireita}
                orientation="horizontal"
                mode="desktop"
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xl={9} xs={12} align="center">
            {window.location.pathname === "/"
              ? props.children[0]
              : props.children}
            {windowSize.width < 1000 ? (
              <Row>
                <Col xs={12}>
                  <AdSlideShow
                    publications={listaMobile}
                    orientation="mobile"
                    mode="mobile"
                  />
                </Col>
              </Row>
            ) : windowSize.width >= 1000 && windowSize.width < 1200 ? (
              <Row>
                <Col className="align-start-v" xs={6}>
                  <AdSlideShow
                    publications={verticalSuperiorDireita}
                    orientation="horizontal-mobile"
                    mode="mobile"
                  />
                </Col>
                <Col className="align-start-v" xs={6}>
                  <AdSlideShow
                    publications={verticalInferiorDireita}
                    orientation="horizontal-mobile"
                    mode="mobile"
                  />
                </Col>
              </Row>
            ) : null}
            {window.location.pathname === "/" ? props.children[1] : null}
          </Col>

          {windowSize.width >= 1200 ? (
            <>
              <Col className="align-start-v" xs={12} xl={3}>
                <AdSlideShow
                  publications={verticalSuperiorDireita}
                  orientation="vertical"
                  mode="desktop"
                />
                <AdSlideShow
                  publications={verticalInferiorDireita}
                  orientation="vertical"
                  mode="desktop"
                />
              </Col>
            </>
          ) : null}
        </Row>
        {windowSize.width < 1000 ? null : (
          <Row>
            <Col xs={4}>
              <AdSlideShow
                publications={horizontalInferiorEsquerda}
                orientation="horizontal"
                mode="desktop"
              />
            </Col>
            <Col xs={4}>
              <AdSlideShow
                publications={horizontalInferiorCentral}
                orientation="horizontal"
                mode="desktop"
              />
            </Col>
            <Col xs={4}>
              <AdSlideShow
                publications={horizontalInferiorDireita}
                orientation="horizontal"
                mode="desktop"
              />
            </Col>
          </Row>
        )}
      </Container>
    </main>
  );
}
