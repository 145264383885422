import "./index.css";

export function Footer() {
    return (
        <footer className="footer">
            <p>Caderno 3 - Todos os direitos Reservados © 2023</p>
            <p>
                Desenvolvido por{" "}
                <a
                    href="http://www.kadev.com.br"
                    title="www.kadev.com.br"
                    target="_blank"
                    rel="noreferrer"
                >
                    KaDev
                </a>
            </p>
        </footer>
    );
}
