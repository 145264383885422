import { Link } from "react-router-dom";
import "./styles.css";
import erro_404 from "../../assets/images/erro_404.png";
export function NotFoundPage() {
    return (
        <div className="page-not-found">
            <img src={erro_404} alt="Página não encontrada" />
            <p>
                <Link to="/">Voltar para o início </Link>
            </p>
        </div>
    );
}
