import api from "../services/api";

import { useState, useEffect } from "react";
const useFetch = (url, initialData) => {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(url)
      .then((res) => {
        setData(res.data);
      })
      .catch((res) => {
        setError(res.data);
      })
      .finally(() => setLoading(false));
  }, []);

  return { data, loading, error };
};
export default useFetch;
