/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import "./index.css";
import Button from "../../../components/Button/index.jsx";
import AppContext from "../../../contexts/AppContext.js";
import { useContext } from "react";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Header } from "../../../components/Header/index.jsx";
import { MainRaw } from "../../../components/MainRaw/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";
import { categories } from "../../../utils/categories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export function ListAds() {
  const { currentUser, setCurrentUser } = useContext(AppContext);
  const [ads, setAds] = useState([]);
  const [positions, setPositions] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [adsFiltered, setAdsFiltered] = useState([]);
  const [filter, setFilter] = useState({
    text: "",
    position_id: "Todas",
    advertiser_id: "Todos",
    is_active: "Todos",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    async function fetchAds() {
      try {
        const advertiserList = await api.get(`/advertisers`);
        setAdvertisers(advertiserList.data);
        const positionList = await api.get(`/ads/positions`);
        setPositions(positionList.data);
        const adList = await api.get(`/ads/admin`);
        setAdsFiltered(adList.data);
        setAds(adList.data);
        setFilter({
          text: "",
          position_id: "Todas",
          advertiser_id: "Todos",
          is_active: "Todos",
        });
      } catch (err) {
        console.log(err.response.data.message || err.response.message);
        setErrorMessage(err.response.data.message || err.response.message);
      }
    }
    fetchAds();
  }, [refetch]);

  useEffect(() => {
    let listAds = ads.filter((ad) => {
      const nameOk = `${ad.description}`.includes(filter.text);
      const positionOk =
        ad.position_id == filter.position_id || filter.position_id === "Todas";
      const advertiserOk =
        ad.advertiser_id == filter.advertiser_id ||
        filter.advertiser_id === "Todos";
      const isActiveOk =
        filter.is_active === "Todos" ||
        JSON.parse(filter.is_active) == ad.is_active;
      if (nameOk && positionOk && advertiserOk && isActiveOk) return true;
      return false;
    });
    return setAdsFiltered(listAds);
  }, [filter]);

  const deleteHandler = async (ad) => {
    if (window.confirm(`Tem certeza que deseja inativar o anúncio?`)) {
      try {
        await api.delete(`/ads/${ad.id}`);

        setRefetch((refetch) => !refetch);
      } catch (err) {
        console.log(err.response.data.message);
        window.alert(`Ocorreu um erro ao deletar o anunciante.`);
      }
    }
  };
  return (
    <>
      <Header></Header>
      <MainRaw>
        <div className="list-ads">
          <div className="list-ads-button-row">
            <Button
              text="Cadastrar"
              confirmType={false}
              handleClick={"/ads/new"}
            />
          </div>
          <h4>Lista de Anúncios</h4>
          <label className="list-ads-error-message">
            <p htmlFor="list-ads-error-message">{errorMessage}</p>
          </label>

          <div className="filter-ads">
            <span style={{ paddingBottom: 5, paddingRight: 10 }}>Filtros:</span>

            <label className="filter-list-ads-select">
              Anunciante
              <select
                onChange={(e) => {
                  setFilter({ ...filter, advertiser_id: e.target.value });
                }}
                value={filter.advertiser_id || null}
              >
                <option value="Todos">Todos</option>
                {advertisers.map((advertiser) => {
                  return (
                    <option key={advertiser.id} value={advertiser.id}>
                      {advertiser.name || advertiser.company_name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="filter-list-ads-select">
              Posição
              <select
                onChange={(e) => {
                  setFilter({ ...filter, position_id: e.target.value });
                }}
                value={filter.position_id || null}
              >
                <option value="Todas">Todas</option>
                {positions.map((position) => {
                  return (
                    <option key={position.id} value={position.id}>
                      {position.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="filter-list-ads-select">
              Status
              <select
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    is_active: e.target.value,
                  });
                }}
                value={filter.is_active || null}
              >
                <option value="Todos">Todos</option>
                <option value="true">Ativos</option>
                <option value="false">Inativos</option>
              </select>
            </label>
            <label className="label-filter-search">
              <FontAwesomeIcon icon={faSearch} />
              <input
                type="input"
                value={filter.text || ""}
                placeholder="Digite caracteres que estejam incluídas na descrição do anúncio"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    text: e.target.value,
                  });
                }}
              />
            </label>
          </div>
          <div className="list-ads-table">
            <table>
              <thead>
                <tr>
                  <th className="list-ads-advertiser">Anunciante</th>
                  <th className="list-ads-position">Posição</th>
                  <th className="list-ads-description">Sequência</th>
                  <th className="list-ads-description">Descrição</th>
                  <th className="list-ads-expiration-date">Data de Validade</th>
                  <th className="list-ads-is-active">Situação</th>
                  <th className="list-ads-actions">Editar</th>
                  <th className="list-ads-actions">Deletar</th>
                </tr>
              </thead>

              <tbody>
                {adsFiltered.map((ad, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: !ad.is_active ? "#ff888866" : null,
                      }}
                    >
                      <td
                        className="list-ads-advertiser"
                        title={
                          ad?.advertiser_name || ad?.advertiser_company_name
                        }
                      >
                        <span>
                          {ad?.advertiser_name || ad?.advertiser_company_name}
                        </span>
                      </td>
                      <td
                        className="list-ads-position"
                        title={ad?.position_name}
                      >
                        <span>{ad?.position_name}</span>
                      </td>
                      <td className="list-ads-sequence">{ad?.sequence}</td>
                      <td
                        className="list-ads-description"
                        title={ad?.description}
                      >
                        <span>{ad?.description}</span>
                      </td>
                      <td
                        className="list-ads-expiration-date"
                        title={ad.expiration_date}
                      >
                        {ad.expiration_date
                          ? new Date(ad.expiration_date).toLocaleDateString(
                              "pt-BR"
                            )
                          : "Indeterminada"}
                      </td>
                      <td
                        className="list-ads-is-active"
                        title={ad?.is_active ? "Ativo" : "Inativo"}
                      >
                        {ad?.is_active ? "Ativo" : "Inativo"}
                      </td>
                      <td className="list-ads-actions">
                        <Link to={`/ads/update/${ad.external_id}`}>
                          <FontAwesomeIcon className="icon-edit" icon={faPen} />
                        </Link>
                      </td>
                      <td className="list-ads-actions">
                        <Link to={`/ads`} onClick={() => deleteHandler(ad)}>
                          <FontAwesomeIcon
                            className="icon-delete"
                            icon={faTrash}
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
