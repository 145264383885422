import { Header } from "../../components/Header/index";
import { Main } from "../../components/Main/index";
import { Footer } from "../../components/Footer/index";
import { SlideShow } from "../../components/SlideShow/index";
import { LastByCategory } from "../../components/LastByCategory/index";

export function Home() {
    return (
        <>
            <Header></Header>
            <Main>
                <SlideShow></SlideShow>
                <LastByCategory></LastByCategory>
            </Main>
            <Footer></Footer>
        </>
    );
}
