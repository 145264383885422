import "./index.css";
import noImageItem from "../../assets/images/no-image-item.png";
import { Link } from "react-router-dom";

export function CardPublication(props) {
  const { publication } = props;

  return (
    publication?.created_at && (
      <Link
        className="card-publication-link"
        to={`/publications/${publication.external_id}`}
      >
        <div className="card-publication">
          <div className="card-image">
            <img
              src={
                publication?.gdrive_image_ids[0]
                  ? // ? `https://drive.google.com/uc?export=view&id=${publication?.gdrive_image_ids[0]}`
                    `https://drive.google.com/thumbnail?id=${publication?.gdrive_image_ids[0]}&sz=w1000`
                  : publication.images[0] || noImageItem
              }
              alt=""
            />
          </div>

          <div className="card-description">
            {props.hideCategory ? (
              <h4 title={publication?.title}>
                {/* {publication?.title?.length > 86
                                    ? publication?.title
                                          .substring(0, 86)
                                          .concat("...")
                                    : publication?.title} */}
                {publication?.title}
              </h4>
            ) : (
              <>
                <h3>{publication?.category}</h3>
                <h5 title={publication?.title}>
                  {/* {publication?.title?.length > 72
                                        ? publication?.title
                                              .substring(0, 72)
                                              .concat("...")
                                        : publication?.title} */}
                  {publication?.title}
                </h5>
              </>
            )}
            <p>
              {new Date(publication?.updated_at).toLocaleDateString("pt-BR", {
                dateStyle: "full",
              })}
            </p>
          </div>
        </div>
      </Link>
    )
  );
}
