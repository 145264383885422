import "./index.css";
import { Link } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
const Button = ({
    type = "button",
    handleClick = undefined,
    text,
    confirmType,
    style = {},
}) => {
    return (
        <div style={{ ...style, marginBlock: 5 }}>
            {confirmType ? (
                <button
                    className="button-component"
                    type={type}
                    onClick={handleClick}
                    style={{
                        backgroundColor: "var(--first-color)",
                        color: "white",
                    }}
                >
                    {text}
                </button>
            ) : (
                <button
                    type={type}
                    className="button-component"
                    style={{
                        backgroundColor: "white",
                    }}
                >
                    <Link
                        to={handleClick}
                        style={{
                            color: "var(--first-color)",
                        }}
                    >
                        {text}
                    </Link>
                </button>
            )}
        </div>
    );
};

export default Button;
