export const categories = [
    "GERAL",
    "POLÍTICA",
    "EDUCAÇÃO",
    "ECONOMIA",
    "ESPORTE",
    "SEGURANÇA",
    "SAÚDE",
    "OBITUÁRIO",
];
