import React, { useEffect, useState } from "react";
import { Fade } from "react-slideshow-image";
import { Link } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import "./index.css";
import api from "../../services/api";
import noImageItem from "../../assets/images/no-image-item.png";

export function SlideShow() {
  const [publications, setPublications] = useState([]);
  useEffect(() => {
    async function fetchHighlights() {
      try {
        const publicationList = await api.get(`/publications/highlights`);
        return setPublications(publicationList.data);
      } catch (err) {
        console.log(err.response.data.message || err.response.message);
      }
    }
    fetchHighlights();
  }, []);
  return !publications.length ? null : (
    <div className="slide-container">
      <Fade pauseOnHover={true}>
        {publications.map((publication, index) => (
          <div
            key={index}
            className="slideshow-image"
            title={publication?.title}
          >
            <Link
              className="slide-publication-link"
              to={`/publications/${publication.external_id}`}
            >
              <div className="slideshow-caption">
                {publication?.title?.length > 45
                  ? publication?.title.substring(0, 45).concat("...")
                  : publication?.title}
              </div>

              <img
                src={
                  publication.gdrive_image_ids[0]
                    ? // ? `https://drive.google.com/uc?export=view&id=${publication.gdrive_image_ids[0]}`
                      `https://drive.google.com/thumbnail?id=${publication.gdrive_image_ids[0]}&sz=w1000`
                    : publication.images[0] || noImageItem
                }
                alt={publication.title}
              />
            </Link>
          </div>
        ))}
      </Fade>
    </div>
  );
}
