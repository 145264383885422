import { Header } from "../../components/Header";
import { Main } from "../../components/Main";
import { Footer } from "../../components/Footer";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export function Contact() {
    return (
        <>
            <Header></Header>
            <Main>
                <article className="contact">
                    <h3>Contato</h3>
                    <span>
                        <a href="mailto:domingostormes@gmail.com">
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="contact-email"
                            />{" "}
                            domingostormes@gmail.com
                        </a>
                    </span>
                    <p>
                        <FontAwesomeIcon
                            icon={faWhatsapp}
                            className="contact-whatsapp"
                        />
                        (55) 99621-7271
                    </p>
                </article>
            </Main>
            <Footer></Footer>
        </>
    );
}
