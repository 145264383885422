/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import "./index.css";
import AppContext from "../../../contexts/AppContext.js";
import Button from "../../../components/Button/index.jsx";
import { useContext } from "react";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Header } from "../../../components/Header/index.jsx";
import { MainRaw } from "../../../components/MainRaw/index.jsx";
import { Footer } from "../../../components/Footer/index.jsx";
import { categories } from "../../../utils/categories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export function ListAdvertisers() {
  const { currentUser, setCurrentUser } = useContext(AppContext);
  const [advertisers, setAdvertisers] = useState([]);
  const [advertisersFiltered, setAdvertisersFiltered] = useState([]);
  const [filter, setFilter] = useState({ text: "", type: "Todos" });

  const [errorMessage, setErrorMessage] = useState("");

  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    async function fetchAdvertisers() {
      try {
        const advertiserList = await api.get(`/advertisers`);
        setAdvertisersFiltered(advertiserList.data);
        return setAdvertisers(advertiserList.data);
      } catch (err) {
        console.log(err.response.data.message || err.response.message);
        setErrorMessage(err.response.data.message || err.response.message);
      }
    }
    fetchAdvertisers();
  }, [refetch]);

  useEffect(() => {
    let listAdvertisers = advertisers.filter((advertiser) => {
      const nameOk =
        `${advertiser.name} ${advertiser.company_name} ${advertiser.cpf} ${advertiser.cnpj}`.includes(
          filter.text
        );
      const typeOk = advertiser.type === filter.type || filter.type === "Todos";
      if (nameOk && typeOk) return true;
      return false;
    });
    return setAdvertisersFiltered(listAdvertisers);
  }, [filter]);

  const deleteHandler = async (advertiser) => {
    if (
      window.confirm(
        `Tem certeza que deseja remover o anunciante "${
          advertiser.name || advertiser.company_name
        }"?`
      )
    ) {
      try {
        await api.delete(`/advertisers/${advertiser.id}`);

        setRefetch((refetch) => !refetch);
      } catch (err) {
        console.log(err.response.data.message);
        window.alert(`Ocorreu um erro ao deletar o anunciante.`);
      }
    }
  };
  return (
    <>
      <Header></Header>
      <MainRaw>
        <div className="list-advertisers">
          <div className="list-advertisers-button-row">
            <Button
              text="Cadastrar"
              confirmType={false}
              handleClick={"/advertisers/new"}
            />
          </div>
          <h4>Lista de Anunciantes</h4>
          <label className="list-advertisers-error-message">
            <p htmlFor="list-advertisers-error-message">{errorMessage}</p>
          </label>

          <div className="filter-advertisers">
            <span>Filtros:</span>

            <label className="filter-list-advertiser-type">
              Tipo
              <select
                onChange={(e) => {
                  setFilter({ ...filter, type: e.target.value });
                }}
                value={filter.type || null}
              >
                <option value="Todos">Todos</option>
                <option value="Pessoa Física">Pessoa Física</option>
                <option value="Pessoa Jurídica">Pessoa Jurídica</option>
              </select>
            </label>
            <label className="label-filter-search">
              <FontAwesomeIcon icon={faSearch} />
              <input
                type="input"
                value={filter.text || ""}
                placeholder="Digite caracteres que estejam incluídas no nome/razão social ou CPF/CNPJ"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    text: e.target.value,
                  });
                }}
              />
            </label>
          </div>
          <div className="list-advertisers-table">
            <table>
              <thead>
                <tr>
                  <th className="list-advertisers-type">Tipo</th>
                  <th className="list-advertisers-name-company">
                    Nome/Razão Social
                  </th>
                  <th className="list-advertisers-cpf-cnpj">CPF/CNPJ</th>
                  <th className="list-advertisers-phone">Telefone</th>
                  <th className="list-advertisers-actions">Editar</th>
                  <th className="list-advertisers-actions">Deletar</th>
                </tr>
              </thead>

              <tbody>
                {advertisersFiltered.map((advertiser, index) => {
                  let cpfComPontos = null;
                  if (advertiser.cpf) {
                    cpfComPontos = advertiser.cpf.split("");
                    cpfComPontos.splice(3, 0, ".");
                    cpfComPontos.splice(7, 0, ".");
                    cpfComPontos.splice(11, 0, "-");
                  }
                  let cnpjComPontos = null;
                  if (advertiser.cnpj) {
                    cnpjComPontos = advertiser.cnpj.split("");
                    cnpjComPontos.splice(2, 0, ".");
                    cnpjComPontos.splice(6, 0, ".");
                    cnpjComPontos.splice(10, 0, "/");
                    cnpjComPontos.splice(15, 0, "-");
                  }
                  return (
                    <tr key={index}>
                      <td
                        className="list-advertisers-type"
                        title={advertiser?.type}
                      >
                        <span>{advertiser?.type}</span>
                      </td>
                      <td
                        className="list-advertisers-name-company"
                        title={advertiser.name || advertiser.company_name}
                      >
                        <span>
                          {advertiser.type === "Pessoa Física"
                            ? advertiser.name
                            : advertiser.company_name}
                        </span>
                      </td>
                      <td className="list-advertisers-cpf-cnpj">
                        {advertiser.type === "Pessoa Física"
                          ? cpfComPontos
                          : cnpjComPontos}
                      </td>
                      <td className="list-advertisers-phone">
                        {advertiser.phone}
                      </td>
                      <td className="list-advertisers-actions">
                        <Link
                          to={`/advertisers/update/${advertiser.external_id}`}
                        >
                          <FontAwesomeIcon className="icon-edit" icon={faPen} />
                        </Link>
                      </td>
                      <td className="list-advertisers-actions">
                        <Link
                          to={`/advertisers`}
                          onClick={() => deleteHandler(advertiser)}
                        >
                          <FontAwesomeIcon
                            className="icon-delete"
                            icon={faTrash}
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </MainRaw>
      <Footer></Footer>
    </>
  );
}
